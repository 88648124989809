
import './App.css';
import React, { useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './Script/Header';
import Footer from './Script/Footer';
import About from './Script/About';
import Hero from './Script/Hero';
import StudentRegistrationForm from './Script/StudentRegistrationForm';
import Events from './Script/Events';
import Layout from './Script/Layout';
import NewPage from './Script/NewPage';
import ConfirmDetails from './Script/ConfirmDetails';
import PrintPage from './Script/PrintPage';
import SearchPage from './Script/SearchPage';
import ContactUs from './Script/ContactUs';
import TermsAndConditions from './Script/TermsAndConditions';
import PrivacyPolicy from './Script/PrivacyPolicy';
import RefundPolicy from './Script/RefundPolicy';
import Disclaimer from './Script/Disclaimer';
import ShippingAndDelivery from './Script/ShippingAndDelivery';
import StudentResult from './Script/StudentResult';
import ExcelUploader from './Script/ExcelUploader';
function App() {
  const [formData, setFormData] = useState({
    studentName: '',
    fatherName: '',
    address: '',
    phone: '',
    dateOfBirth: '',
    schoolName: '',
    section: '',
    roll: ''
  });
    return (
        <Router>
         <Layout>
          <main className="main-content">
            <Routes>
            <Route path='/' element={<NewPage/>}/>
            <Route path="/student" element={<StudentRegistrationForm formData={formData} setFormData={setFormData} />} />
            <Route path="/confirm" element={<ConfirmDetails formData={formData} />} />
               <Route path='/Events' element={<Events/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/print' element={<PrintPage/>}/>
                <Route path='/search' element={<SearchPage/>}/>
                <Route path='/contact' element={<ContactUs/>}/>
                <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                <Route path='/refund-policy' element={<RefundPolicy/>}/>
                <Route path='/disclaimer' element={<Disclaimer/>}/>
                <Route path='/shipping-and-delivery' element={<ShippingAndDelivery/>}/>
                <Route path='/result' element={<StudentResult/>}/>
                <Route path='/resultupload' element={<ExcelUploader/>}/>
            </Routes>
          </main>
          </Layout>
        </Router>
      );
}

export default App;
