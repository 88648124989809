import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";

import { getFirestore } from "firebase/firestore";
import * as XLSX from "xlsx";

const db = getFirestore(); // Initialize Firestore

const ExcelUploader = () => {
  const [data, setData] = useState([]); // Store parsed Excel data
  const [columns, setColumns] = useState([]); // Store table columns

  // Handle file upload and parse Excel file
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet); // Convert sheet to JSON

      setData(parsedData);
      setColumns(Object.keys(parsedData[0] || {})); // Set column headers from keys
    };
    reader.readAsBinaryString(file);
  };

  // Save table data to Firestore
  const saveToFirestore = async () => {
    try {
      const batch = data.map(async (row) => {
        await addDoc(collection(db, "excelData"), row); // Add each row to Firestore
      });

      await Promise.all(batch);
      alert("Data successfully saved to Firestore!");
    } catch (error) {
      console.error("Error saving data to Firestore:", error);
      alert("Error saving data.");
    }
  };

  return (
    <div>
      <h1>Upload and Display Excel File</h1>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />

      {data.length > 0 && (
        <div>
          <h2>Preview Data</h2>
          <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th key={index} style={{ padding: "8px", textAlign: "left" }}>
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} style={{ padding: "8px" }}>
                      {row[col]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={saveToFirestore} style={{ marginTop: "20px" }}>
            Save to Firestore
          </button>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;
