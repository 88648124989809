import React, { useState } from "react";
import { Button, Form, Row, Col, Container, Table } from "react-bootstrap";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Firestore config file

const StudentResult = () => {
  const [roll, setRoll] = useState(""); // State for Roll Number
  const [className, setClassName] = useState(""); // State for Class
  const [studentData, setStudentData] = useState(null); // State for fetched student data

  const handleSearch = async () => {
    if (!roll.trim() || !className.trim()) {
      alert("Please enter both Roll Number and Class.");
      return;
    }

    try {
      // Collection reference
      const studentsRef = collection(db, "excelData");

      // Query Firestore with correct types and trimmed input
      const q = query(
        studentsRef,
        where("Roll", "==", parseInt(roll.trim())), // Ensure Roll matches Firestore type
        where("Class", "==", className.trim()) // Ensure Class matches Firestore type
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        alert("No results found for the provided Roll Number and Class.");
        setStudentData(null);
        return;
      }

      // Extract data
      const student = querySnapshot.docs[0].data(); // Assuming single match
      setStudentData(student);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data. Please try again.");
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center">Search Student Results</h1>

      <Form className="my-4">
        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formRoll">
              <Form.Label>Roll Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Roll Number"
                value={roll}
                onChange={(e) => setRoll(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group controlId="formClass">
              <Form.Label>Class</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Class"
                value={className}
                onChange={(e) => setClassName(e.target.value.toUpperCase())}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={12}>
            <Button variant="primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </Form>

      <div className="mt-4">
        {studentData ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Field</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Name</strong></td>
                <td>{studentData.Name}</td>
              </tr>
              <tr>
                <td><strong>Roll</strong></td>
                <td>{studentData.Roll}</td>
              </tr>
              <tr>
                <td><strong>Class</strong></td>
                <td>{studentData.Class}</td>
              </tr>
              <tr>
                <td><strong>School Name</strong></td>
                <td>{studentData["SchoolName"]}</td>
              </tr>
              <tr>
                <td><strong>Math</strong></td>
                <td>{studentData["Math30"]}/30</td>
              </tr>
              <tr>
                <td><strong>EVS</strong></td>
                <td>{studentData["EVS30"]}/30</td>
              </tr>
              <tr>
                <td><strong>English</strong></td>
                <td>{studentData["Eng30"]}/30</td>
              </tr>
              <tr>
                <td><strong>GK</strong></td>
                <td>{studentData["GK10"]}/10</td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td>{studentData["Total100"]}/100</td>
              </tr>
              <tr>
                <td><strong>Rank</strong></td>
                <td>{studentData["Rank"]}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p className="text-center">No data to display.</p>
        )}
      </div>
    </Container>
  );
};

export default StudentResult;
